import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

import logo from "../../../assets/images/logo_rounded_5.png";
// import logo from "../../../assets/images/mobile_logo.png";

const MobileTopbar = ({ scrolled }) => {
  // const location = useLocation();
  // const path=location.pathname
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [expanded, setExpanded] = useState(false);
  // useEffect(() => {
  //   setExpanded(false);
  // }, [path]);

  return (
    <Navbar
      className={
        scrolled > 0 ? "app__header_mobile_scrolled" : "app__header_mobile"
      }
      variant="dark"
      expand="md"
      expanded={expanded}
      style={{ textAlign: "center" }}
    >
      <Container fluid>
        <Navbar.Brand className="me-auto">
          <Link to={"/"}>
            <img
              src={logo}
              className={!scrolled&&expanded?"app__mobile_logo_rounded_hide":"app__mobile_logo_rounded"}
              alt="Nosztalgia desszertműhely logó"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={() => setExpanded(!expanded)}
          aria-controls="navbarScroll"
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>
              {/* <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}> */}
              Kezdőlap
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/rolunk"
              onClick={() => {
                setExpanded(false);
                scrollToTop();
              }}
            >
              Rólunk
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/termekek"
              onClick={() => {
                setExpanded(false);
                scrollToTop();
              }}
            >
              Termékeink
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/kapcsolat"
              onClick={() => {
                setExpanded(false);
                scrollToTop();
              }}
            >
              Kapcsolat
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/kornyezetvedelem"
              onClick={() => {
                setExpanded(false);
                scrollToTop();
              }}
            >
              Környezettudatosság
            </Nav.Link>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Nav.Link
                aria-hidden="true"
                as={Link}
                onClick={scrollToTop}
                style={{ marginRight: "25px" }}
                aria-label={"asd"}
                target="_blank"
                to={"https://www.facebook.com/nosztalgiacukraszda"}
              >
                <FontAwesomeIcon size="2x" icon={faFacebookF} />
              </Nav.Link>

         {/* <Nav.Link
                as={Link}
                onClick={scrollToTop}
                aria-label={"asd"}
                target="_blank"
                to={"https://www.instagram.com/nosztalgiadesszert/"}
              >
                <FontAwesomeIcon
                  aria-hidden="true"
                  size="2x"
                  icon={faInstagram}
                />
              </Nav.Link>     
            */}      
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MobileTopbar;
