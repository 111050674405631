import { lazy, Suspense } from "react";
import { Container } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";

import CookieConsent from "react-cookie-consent";
import Loader from "./components/Loader/Loader";
import Header from "./layout/Header/Header";



// const Header = lazy(() => import("./layout/Header/Header"));
const Footer = lazy(() => import("./layout/Footer/Footer"));

const Homepage = lazy(() => import("./pages/Homepage/Homepage"));
const KornyezetPage = lazy(() => import("./pages/KornyezetPage/KornyezetPage"));
const AboutUs = lazy(() => import("./pages/AboutUs/AbousUS"));
const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
const Product = lazy(() => import("./pages/Product/Product"));
const ProductCategories = lazy(() => import("./pages/ProductCategories/ProductCategories"));
const ProductCategory = lazy(() => import("./pages/ProductCategory/ProductCategory"));

const TermsPage = lazy(() => import("./pages/OtherPages/TermsPage/TermsPage"));
const Page404 = lazy(() => import("./pages/OtherPages//Page404/Page404"));



const App=()=> {
  return (
    <>
        <Header />  
      <Suspense fallback={<Loader />}>
        <Container fluid style={{minHeight:"72vh",
        //  maxWidth:"1980px"
         }} >
         

          <CookieConsent
            location="bottom"
            buttonText="Rendben"
            cookieName="<cookieName>"
            style={{ background: "#2B373B", justifyContent:"center"}}
            buttonStyle={{ color: "#ffffff", backgroundColor:"#a81903", fontSize: "13px" }}
            expires={150}
          >
            Az oldalon cookie-kat használunk, hogy a legjobb felhasználói
            élményt nyújthassuk.
            <span style={{ color: "blue" }}>
              <a href="/aszf">További információk</a>
            </span>
          </CookieConsent>
  
          <Routes>
            <Route path="/termekek/:slug/:slug" element={<Product />} />
            <Route path="/termekek/:slug" element={<ProductCategory />} />
            <Route path="/termekek" element={<ProductCategories />} />
            <Route path="/kornyezetvedelem" element={<KornyezetPage />} />
            <Route path="/rolunk" element={<AboutUs />} />
            <Route path="/kapcsolat" element={<ContactUs />} />
            <Route path="/aszf" element={<TermsPage />} />
            <Route path="/" element={<Homepage />} />
            <Route path="/*" element={<Page404 />} />
          </Routes>
        </Container>
        <Footer  />
      </Suspense>
    </>
  );
}

export default App;
