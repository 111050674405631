import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import logo from "../../../assets/images/logo_rounded_5.png";
// import logo from "../../../assets/images/logo_rounded.png";
// import logo from "../../../assets/images/logo_admin.png";

const Topbar = ({ scrolled }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Navbar
      className={scrolled > 0 ? "app__header_scrolled" : "app__header"}
      variant="dark"
      expand="md"
    >
      <Container>
        <Nav className="ms-auto">
          <Nav.Link
            as={Link}
            onClick={scrollToTop}
            className="app__header_items"
            to={"/"}
          >
            Kezdőlap
          </Nav.Link>
          <Nav.Link
            as={Link}
            onClick={scrollToTop}
            className="app__header_items"
            to={"/rolunk"}
          >
            Rólunk
          </Nav.Link>
          <Nav.Link
            as={Link}
            onClick={scrollToTop}
            className="app__header_items"
            to={"/termekek"}
          >
            Termékeink
          </Nav.Link>
        </Nav>

        <Navbar.Brand className="mx-auto">
          <Link to={"/"} onClick={scrollToTop}>
            <img
              src={logo}
              className="app__header_logo_rounded"
              alt="Nosztalgia desszertműhely logó"
            />
          </Link>
        </Navbar.Brand>

        <Nav className="me-auto">
          <Nav.Link
            as={Link}
            onClick={scrollToTop}
            className="app__header_items"
            to={"/kapcsolat"}
          >
            Kapcsolat
          </Nav.Link>
          <Nav.Link
            as={Link}
            onClick={scrollToTop}
            className="app__header_items"
            to={"/kornyezetvedelem"}
          >
            Környezettudatosság
          </Nav.Link>
          <Nav.Link
            as={Link}
            onClick={scrollToTop}
            aria-label={"asd"}
            target={"_blank"}
            className="app__header_items_facebook"
            to={"https://www.facebook.com/nosztalgiacukraszda"}
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </Nav.Link>
            {/* <Nav.Link
            as={Link}
            onClick={scrollToTop}
            aria-label={"asd"}
            target={"_blank"}
            className="app__header_items_facebook"
            to={"https://www.instagram.com/nosztalgiadesszert/"}
          >
            <FontAwesomeIcon icon={faInstagram} />
          </Nav.Link> */}        
       </Nav>
      </Container>
    </Navbar>
  );
};

export default Topbar;
